<template>
  <el-main>
    <el-form class="select_store" label-width="85px">
      <el-form-item label="统计时间：">
        <el-date-picker v-model="goodsAnalyseForm.month_date" value-format="yyyy-MM" default-value type="month" placeholder="选择日期" @change="getTime"></el-date-picker>
      </el-form-item>
      <el-form-item label="选择店铺：">
        <el-select v-model="store_id" filterable placeholder="请选择" @change="selectStore">
          <el-option v-for="item in storeList" :key="item.id" :label="item.store_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="day-data">
      <div class="goods-analysis">
        <div class="header">
          <span></span>
          <span>商品分析</span>
        </div>
        <tab :list="tab_options" @selectTab="getGoodsAnalysisIndex"></tab>
        <overallKanban ref="goods_analysis" domId="goods-analysis" :content="goodsAnalyse"></overallKanban>
      </div>
      <div class="sales-analysis">
        <div class="header">
          <span></span>
          <span>销量分析</span>
        </div>
        <tab :list="tab_options1" @selectTab="getSalesAnalysisIndex"></tab>
        <div class="type-rank" v-show="salesAnalyseForm.tab == 'classify'">
          <div class="table">
            <div class="title">类目支付金额排行</div>
            <el-table :data="salesAnalys.list">
              <el-table-column prop="rank" label="排名"></el-table-column>
              <el-table-column prop="name" label="商品大类"></el-table-column>
              <el-table-column prop="amount" label="支付金额"></el-table-column>
              <el-table-column prop="percent" label="支付金额占比（%）">
                <template v-slot="{ row }">
                  {{ row.percent + '%' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div id="type-rank-pie"></div>
        </div>
        <div class="goods-rank" v-show="salesAnalyseForm.tab == 'goods'">
          <goodsRank :rankList="salesAnalysGoodsRank"></goodsRank>
        </div>
      </div>
      <div class="error-goods">
        <div class="header">
          <span></span>
          <span>异常商品</span>
        </div>
        <tab :list="tab_options2" @selectTab="getBnormalGoodsIndex"></tab>
        <div class="error-tips">
          <p>
            {{ bnormalGoodsForm.tab == 'sales_down' ? '销量下跌' : bnormalGoodsForm.tab == 'unsalable' ? '商品滞销' : '流量下跌' }}：最近7天({{ error_goods_total.lately_date }}){{
              bnormalGoodsForm.tab == 'sales_down' ? '销量' : bnormalGoodsForm.tab == 'unsalable' ? '商品' : '流量'
            }}较上一个销量周期7天({{ error_goods_total.lastWeek_date }}){{ bnormalGoodsForm.tab == 'sales_down' ? '销量下跌' : bnormalGoodsForm.tab == 'unsalable' ? '商品滞销' : '流量下跌' }}top{{
              bnormalGoods.total_number
            }}
          </p>
          <!-- <p>经营建议：优化商品标题和描述，加强引流，同时利用促销优惠提升买家下单转化</p> -->
        </div>
        <div class="list">
          <el-table :data="bnormalGoods.list" v-show="bnormalGoodsForm.tab == 'sales_down' || bnormalGoodsForm.tab == 'flow_down'">
            <el-table-column prop="goods_name" label="商品">
              <template v-slot="{ row }">
                <div class="error-goods-info">
                  <el-image :src="row.goods_picture">
                    <div slot="error" class="image-slot">
                      <el-image :src="require('@/assets/default-img.png')"></el-image>
                    </div>
                  </el-image>
                  <div>{{ row.goods_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="last_week_sales" label="上周期7天销量"></el-table-column>
            <el-table-column prop="sales" label="最近7天销量"></el-table-column>
            <el-table-column prop="drop_ratio" label="下跌比例"></el-table-column>
          </el-table>
          <el-table :data="bnormalGoods.list" v-show="bnormalGoodsForm.tab == 'unsalable'">
            <el-table-column label="商品">
              <template v-slot="{ row }">
                <div class="error-goods-info">
                  <el-image :src="row.goods_picture">
                    <div slot="error" class="image-slot">
                      <el-image :src="require('@/assets/default-img.png')"></el-image>
                    </div>
                  </el-image>
                  <div>{{ row.goods_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goods_price" label="价格"></el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange($event, 1)"
            @current-change="handleCurrentChange($event, 1)"
            :current-page="bnormalGoodsForm.page"
            :page-sizes="[5, 10, 15]"
            :page-size="bnormalGoodsForm.rows"
            layout="total, sizes, prev, pager, next, jumper"
            :total="bnormalGoods.total_number"
          ></el-pagination>
        </div>
      </div>
      <div class="goods-sales-detail">
        <div class="header">
          <span></span>
          <span>商品销售明细</span>
        </div>
        <div class="target">
          <div>指标：</div>
          <div>
            <el-checkbox v-model="item.checkbox" :label="item.name" v-for="(item, index) in target_list" :key="index" @change="selectTarget">{{ item.name }}</el-checkbox>
          </div>
          <div>
            已选择
            <span style="color: #409eff">{{ goodsDetai.tableHeader.length }}</span>
            /{{ target_list.length }}指标
          </div>
        </div>
        <div class="list">
          <el-form class="search" @submit.native.prevent>
            <el-input placeholder="请输入商品名称" v-model="goodsDetailForm.goods_name" @change="getGoodsDetail" @keyup.enter.native="getGoodsDetail">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-form>
          <el-table :data="goodsDetai.list" width="100%">
            <el-table-column prop="goods_name" label="商品" align="center" width="350">
              <template v-slot="{ row }">
                <div class="goods-info">
                  <el-image :src="row.goods_picture">
                    <div slot="error" class="image-slot">
                      <el-image :src="require('@/assets/default-img.png')"></el-image>
                    </div>
                  </el-image>
                  <span>{{ row.goods_name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="item in goodsDetai.tableHeader" :key="item.key" :property="item.key" :label="item.name" min-width="150" align="center" sortable>
              <template slot-scope="scope">
                <span v-if="scope.column.property == 'visit_orders_conversion' || scope.column.property == 'orders_pay_conversion' || scope.column.property == 'visit_pay_conversion'">
                  {{ scope.row[scope.column.property] + '%' }}
                </span>
                <span v-else>{{ scope.row[scope.column.property] }}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange($event, 0)"
            @current-change="handleCurrentChange($event, 0)"
            :current-page="goodsDetailForm.page"
            :page-sizes="[5, 10, 15]"
            :page-size="goodsDetailForm.rows"
            layout="total, sizes, prev, pager, next, jumper"
            :total="goodsDetai.total_number"
          ></el-pagination>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import * as echarts from 'echarts';
import { getDateformat } from '@/util/getDate';
import { goodsAnalysis } from './data';
import tab from './components/tab';
import overallKanban from './components/overallKanban';
import goodsRank from './components/goodsRank';
export default {
  components: {
    overallKanban,
    goodsRank,
    tab,
  },
  data: function () {
    return {
      //指标列表
      target_list: [
        {
          checkbox: !0,
          name: '总销量',
          key: 'sales',
        },
        {
          checkbox: !0,
          name: '访客数',
          key: 'user_visit_num',
        },
        {
          checkbox: !0,
          name: '浏览量',
          key: 'visit_num',
        },
        {
          checkbox: !0,
          name: '加购件数',
          key: 'add_carts_num',
        },
        {
          checkbox: !0,
          name: '下单件数',
          key: 'orders_num',
        },
        {
          checkbox: !0,
          name: '访问-下单转化率',
          key: 'visit_orders_conversion',
        },
        {
          checkbox: !0,
          name: '支付件数',
          key: 'pay_num',
        },
        {
          checkbox: !0,
          name: '下单-支付转化率',
          key: 'orders_pay_conversion',
        },
        {
          checkbox: !1,
          name: '支付金额',
          key: 'pay_amount',
        },
        {
          checkbox: !1,
          name: '支付均价',
          key: 'pay_average',
        },
        {
          checkbox: !1,
          name: '访问-支付转化率',
          key: 'visit_pay_conversion',
        },
        {
          checkbox: !1,
          name: '支付人数',
          key: 'pay_user_num',
        },
        {
          checkbox: !1,
          name: '支付客单价',
          key: 'pay_user_average',
        },
        {
          checkbox: !1,
          name: '成功退款笔数',
          key: 'refund_num',
        },
        {
          checkbox: !1,
          name: '成功退款金额',
          key: 'refund_amount',
        },
        {
          checkbox: !1,
          name: '成功退款人数',
          key: 'refund_user_num',
        },
        {
          checkbox: !1,
          name: '跳转京东UV',
          key: 'goods_uv',
        }
      ],
      storeList: [],
      store_id: -1,
      tab_options: ['概况', '转化', '动销'],
      tab_options1: ['分类排行', '商品排行'],
      tab_options2: ['销量下跌', '商品滞销', '流量下跌'],
      sales_analysis_type: 0,
      error_goods_total: {
        lately_date: '',
        lastWeek_date: '',
      },
      //商品分析数据
      goodsAnalyse: {
        data: {},
        date: [],
        num_list: [],
      },
      //销量分析数据
      salesAnalys: {},
      //销量分析商品榜单
      //异常商品数据
      bnormalGoods: {
        list: [],
        total_number: 0,
        // sales_down: [],
        // unsalable: [],
        // flow_down: [],
      },
      //商品详情数据
      goodsDetai: {
        tableHeader: [],
        list: [],
        total_number: 0,
      },
      //商品分析请求所需要的数据
      goodsAnalyseForm: {
        store_id: 0,
        tab: 'overview',
        month_date: getDateformat(Date.parse(new Date()) / 1000).slice(0, 7),
      },
      //销量分析请求所需要的数据
      salesAnalyseForm: {
        store_id: 0,
        tab: 'classify',
      },
      //异常商品请求所需要的数据
      bnormalGoodsForm: {
        page: 1,
        rows: 5,
        tab: 'sales_down',
        store_id: 0,
      },
      //商品明细请求所需要的数据
      goodsDetailForm: {
        page: 1,
        rows: 5,
        store_id: 0,
        goods_name: '',
      },
    };
  },
  computed: {
    salesAnalysGoodsRank() {
      return this.$store.state.utils.goodsRankList;
    },
  },
  mounted() {},
  created() {
    let date = Date.parse(new Date()) / 1000;
    this.error_goods_total.lately_date = getDateformat(date - 86400 * 7).slice(0, 10) + '~' + getDateformat(date - 86400).slice(0, 10);
    this.error_goods_total.lastWeek_date = getDateformat(date - 86400 * 14).slice(0, 10) + '~' + getDateformat(date - 86400 * 8).slice(0, 10);
    this.getGoodsAnalyse();
    this.getSalesAnalyse();
    this.getAbnormalGoods();
    this.getGoodsDetail();
    this.getStore();
  },
  methods: {
    getSalesAnalysisIndex(index) {
      this.salesAnalyseForm.tab = index ? 'goods' : 'classify';
      index ? this.$store.dispatch('utils/getAnkingList', { store_id: this.salesAnalyseForm.store_id, is_today: 0 }) : this.getSalesAnalyse();
    },
    getGoodsAnalysisIndex(index) {
      this.goodsAnalyseForm.tab = index == 0 ? 'overview' : index == 1 ? 'convert' : 'sell';
      this.getGoodsAnalyse();
    },
    getBnormalGoodsIndex(index) {
      this.bnormalGoodsForm.tab = index == 0 ? 'sales_down' : index == 1 ? 'unsalable' : 'flow_down';
      this.bnormalGoodsForm.page = 1;
      this.bnormalGoodsForm.rows = 5;
      this.getAbnormalGoods();
    },
    handleSizeChange(val, type) {
      if (type) {
        this.bnormalGoodsForm.rows = val;
        this.getAbnormalGoods();
      } else {
        this.goodsDetailForm.rows = val;
        this.getGoodsDetail();
      }
    },
    handleCurrentChange(val, type) {
      if (type) {
        this.bnormalGoodsForm.page = val;
        this.getAbnormalGoods();
      } else {
        this.goodsDetailForm.page = val;
        this.getGoodsDetail();
      }
    },
    //选择指标
    selectTarget() {
      this.goodsDetai.tableHeader = this.target_list.filter(item => item.checkbox);
    },
    getTime(val) {
      if (val) {
        this.goodsAnalyseForm.month_date = val;
        this.getGoodsAnalyse();
      }
    },
    //选择门店
    selectStore() {
      this.goodsAnalyseForm.store_id = this.store_id;
      this.salesAnalyseForm.store_id = this.store_id;
      this.bnormalGoodsForm.store_id = this.store_id;
      this.goodsDetailForm.store_id = this.store_id;
      this.getGoodsAnalyse();
      this.getAbnormalGoods();
      this.getGoodsDetail();
      this.salesAnalyseForm.tab == 'classify' ? this.getSalesAnalyse() : this.$store.dispatch('utils/getAnkingList', { store_id: this.salesAnalyseForm.store_id, is_today: 0 });
    },
    //获取门店
    getStore() {
      this.$axios.post(this.$api.store.storeList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          res.result.list.unshift({
            id: 0,
            store_name: '总店',
          });
          if (res.result.list.length) {
            res.result.list.unshift({
              id: -1,
              store_name: '全部',
            });
          }
          this.storeList = res.result.list;
        }
      });
    },
    //获取商品分析数据
    getGoodsAnalyse() {
      this.$axios.post(this.$api.data.goodsAnalyse1, this.goodsAnalyseForm).then(res => {
        if (res.code == 0) {
          let info = res.result;
          let tab = this.goodsAnalyseForm.tab;
          this.goodsAnalyse.data = goodsAnalysis.options;
          this.goodsAnalyse.date = info.date;
          if (tab == 'overview') {
            this.goodsAnalyse.num_list = [
              {
                name: '支付件数',
                num_today: info.pay_num_today,
                num_yesterday: info.pay_num_yesterday,
                num_last_week: info.pay_num_last_week,
                num_arr: info.pay_num_arr,
                checked: !0,
              },
              {
                name: '支付金额',
                num_today: info.pay_amount_today,
                num_yesterday: info.pay_amount_yesterday,
                num_last_week: info.pay_amount_last_week,
                num_arr: info.pay_amount_arr,
                checked: !0,
              },
              {
                name: '成功退款笔数',
                num_today: info.refund_num_today,
                num_yesterday: info.refund_num_yesterday,
                num_last_week: info.refund_num_last_week,
                num_arr: info.refund_num_arr,
                checked: !1,
              },
              {
                name: '成功退款金额',
                num_today: info.refund_amount_today,
                num_yesterday: info.refund_amount_yesterday,
                num_last_week: info.refund_amount_last_week,
                num_arr: info.refund_amount_arr,
                checked: !1,
              },
            ];
          } else if (tab == 'convert') {
            this.goodsAnalyse.num_list = [
              {
                name: '商品访客数',
                num_today: info.visit_num_today,
                num_yesterday: info.visit_num_yesterday,
                num_last_week: info.visit_num_last_week,
                num_arr: info.visit_num_arr,
                checked: !0,
              },
              {
                name: '商品浏览量',
                num_today: info.goods_visit_num_today,
                num_yesterday: info.goods_visit_num_yesterday,
                num_last_week: info.goods_visit_num_last_week,
                num_arr: info.goods_visit_num_arr,
                checked: !0,
              },
              {
                name: '被访问商品数',
                num_today: info.user_visit_num_today,
                num_yesterday: info.user_visit_num_yesterday,
                num_last_week: info.user_visit_num_last_week,
                num_arr: info.user_visit_num_arr,
                checked: !1,
              },
              {
                name: '商品加购数',
                num_today: info.add_carts_num_today,
                num_yesterday: info.add_carts_num_yesterday,
                num_last_week: info.add_carts_num_last_week,
                num_arr: info.add_carts_num_arr,
                checked: !1,
              },
            ];
          } else {
            this.goodsAnalyse.num_list = [
              {
                name: '在线商品数',
                num_today: info.goods_num_today,
                num_yesterday: info.goods_num_yesterday,
                num_last_week: info.goods_num_last_week,
                num_arr: info.goods_num_arr,
                checked: !0,
              },
              {
                name: '动销商品数',
                num_today: info.sell_goods_sum_today,
                num_yesterday: info.sell_goods_sum_yesterday,
                num_last_week: info.sell_goods_sum_last_week,
                num_arr: info.sell_goods_sum_arr,
                checked: !0,
              },
              {
                name: '动效率',
                num_today: info.sell_rate_today,
                num_yesterday: info.sell_rate_yesterday,
                num_last_week: info.sell_rate_last_week,
                num_arr: info.sell_rate_arr,
                checked: !1,
              },
            ];
          }
          this.$refs.goods_analysis.initData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取销量分析数据
    getSalesAnalyse() {
      this.$axios.post(this.$api.data.salesAnalyse, this.salesAnalyseForm).then(res => {
        if (res.code == 0) {
          let data = goodsAnalysis.options1;
          let info = res.result || {};
          let series = [];
          let legend = [];
          for (let i in info.list) {
            info.list[i].rank = i + 1;
            series.push({
              value: info.list[i].percent,
              name: info.list[i].name,
            });
            legend.push(info.list[i].name);
          }
          if (info.amount_sum) {
            data.graphic.style.text = ['全店支付金额(元)', Math.floor(info.amount_sum * 100) / 100].join('\n\n');
            if (info.list.every(item => item.percent > 10)) {
              data.series[0].roseType = 'area';
            }
          } else {
            data.graphic.style.text = ['暂无数据'];
          }
          if (!series.length) {
            series.push({
              value: 0,
              itemStyle: {
                color: '#F7F8FA',
                emphasis: { color: '#5470c6' },
              },
            });
            data.series[0].label = {};
          }
          data.series[0].data = series;
          data.legend.data = legend;
          let chartDom = document.getElementById('type-rank-pie');
          let myChart = echarts.init(chartDom);
          myChart.setOption(data);
          this.salesAnalys = info;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取异常商品数据
    getAbnormalGoods() {
      this.$axios.post(this.$api.data.abnormalGoods, this.bnormalGoodsForm).then(res => {
        if (res.code == 0) {
          let bnormalGoods = this.bnormalGoods;
          let tab = this.bnormalGoodsForm.tab;
          let list = res.result.list;
          // if (tab == 'sales_down') {
          //   bnormalGoods.sales_down = list;
          // } else if (tab == 'unsalable') {
          //   bnormalGoods.unsalable = list;
          // } else {
          //   bnormalGoods.flow_down = list;
          // }
          bnormalGoods.total_number = res.result.total_number;
          bnormalGoods.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取商品销售明细
    getGoodsDetail() {
      this.$axios.post(this.$api.data.goodsDetail, this.goodsDetailForm).then(res => {
        if (res.code == 0) {
          this.goodsDetai.tableHeader = this.target_list.filter(item => item.checkbox);
          this.goodsDetai.list = res.result.list;
          this.goodsDetai.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
  .header {
    background: #f8f9fb;
    display: flex;
    align-items: center;
    padding-left: 12px;
    height: 40px;
    span:nth-child(1) {
      width: 3px;
      height: 11px;
      background: #409eff;
      margin-right: 8px;
    }
    span:nth-child(2) {
      color: #333;
    }
  }
  .select_store {
    background: #fff;
    padding: 15px 20px;
    display: flex;
    .el-form-item {
      margin: 0;
      margin-right: 25px;
      &:nth-child(1) {
        .el-select {
          width: 100px;
          margin-right: 10px;
        }
      }
    }
  }
  .day-data {
    padding: 0 20px 20px 20px;
    margin-top: 7px;
    background: #fff;
    .goods-analysis {
      margin-top: 20px;
      .header {
        margin-bottom: 27px;
      }
    }
    .sales-analysis {
      margin-top: 20px;
      .header {
        margin-bottom: 40px;
      }
      .type-rank {
        display: flex;
        margin-top: 30px;
        margin-bottom: 20px;
        .table {
          flex: 1;
          .title {
            font-weight: bold;
            margin-bottom: 20px;
          }
        }
        #type-rank-pie {
          flex: 1;
          height: 420px;
        }
      }
      .goods-rank {
        margin-bottom: 20px;
      }
    }
    .error-goods {
      .header {
        margin-bottom: 25px;
      }
      .error-tips {
        margin-top: 20px;
        font-weight: 500;
        P:nth-child(1) {
          margin-bottom: 10px;
        }
      }
      .el-table {
        margin: 20px 0;
      }
      .list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .error-goods-info {
        display: flex;
        align-items: center;
        .el-image {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin-right: 15px;
        }
      }
    }
    .goods-sales-detail {
      margin-top: 20px;
      .header {
        margin-bottom: 20px;
      }
      .target {
        display: flex;
        flex-wrap: wrap;
        div:nth-child(1) {
          width: 60px;
        }
        div:nth-child(2) {
          flex: 1;
        }
        div:nth-child(3) {
          width: 100%;
          padding-left: 60px;
          color: #999;
        }
        .el-checkbox {
          width: 140px;
          margin-bottom: 15px;
        }
      }
      .goods-info {
        display: flex;
        align-items: center;
        justify-content: center;
        .el-image {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          margin-right: 15px;
        }
        span {
          flex: 1;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 20px;
        .search .el-input {
          margin-bottom: 24px;
          width: 400px;
        }
        .el-table {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
